import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import HowItWorks from '../components/HowItWorks'
import ShopButton from '../components/ShopButton'
import PrintableTests from '../components/Banners/PrintableTests'
import HowItWorksStyle from '../styles/components/howitworks.module.scss'
import PrintablesStyle from '../styles/components/printableTests.module.scss'
import Style from '../styles/components/test.module.scss'

class Test extends React.Component {
  
  _t = (str, html = false) => {
    const _t = this.props.data.staticText.edges[0].node.data.texts;
    return html ? _t[_t.findIndex(item => item.key_text === str)].text.html
      : _t[_t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    const { data: { testPageContent, howItWorks, staticText } } = this.props;
    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    const data = testPageContent.edges[0].node.data;
    const filtered = data.stories.filter((item) => {
      return item.story !== null
    });
    const stories = filtered.map((item) => {
      return {
        projectId: item.story.document[0].data.project_id,
        name: item.story.document[0].data.title.text,
        image: item.story.document[0].data.test_image.url,
        alt_image: item.story.document[0].data.test_alt_attribute,
        type: 'printable',
        isBook: item.story.document[0].data.physical_book,
        isPrintable: item.story.document[0].data.printable,
        inAppPurchase: item.story.document[0].data.in_app_purchase,
        popupBackground: item.story.document[0].data.popup_background,
        pageTracking: 'test'
      }
    });
    

    let storiesRows = [];
    storiesRows.length = Math.ceil(stories.length / 3);
    for (let i = 0; i < stories.length; ++i) {
      let key = Math.floor(i/3);
      if (typeof storiesRows[key] === 'undefined') {
        storiesRows[key] = [];
      }
      storiesRows[key].push(stories[i]);
    }

    return (
      <Layout 
        context={this.props.pageContext} 
        pageTitle={data.seo__meta_title_.text}
        _t={staticPrismicTexts}
      >
        <SEO
          pageTitle={data.seo__meta_title_.text}
          pageDescription={data.seo__meta_description_.text}
          context={this.props.pageContext}
        />
        <div className="page demo">
          <React.Fragment>
            <section className={`${PrintablesStyle.section} ${Style.hero}`}>
              <div className="container">
                <div className="page-head no-padding no-custom-style">
                  <h2>{data.page_title.text}</h2>
                </div>
                <div
                  className={Style.subtitle}
                  dangerouslySetInnerHTML={{ __html: data.page_subtitle.html }}
                ></div>
              </div>

              <PrintableTests 
                stories={filtered} 
                context={this.props.pageContext} 
                _t={staticPrismicTexts}
                regular
              />
            </section>

            <section className={HowItWorksStyle.section}>
              <div className={Style.subtitle} style={{marginBottom: 50}}>
                <p>{this._t('Comment ça marche')}</p>
                <div dangerouslySetInnerHTML={{ __html: this._t('Suivez les 3 étapes pour wakatooner', true) }}></div>
              </div>
              <HowItWorks 
                {...howItWorks} 
                {...{ homepage: true }} 
                animate={false} 
                collapse={true} 
                _t={staticPrismicTexts}
              />
            </section>

            <section className={Style.shopSection}>
              <div className={Style.subtitle} dangerouslySetInnerHTML={{ __html: data.shop_link.html }}></div>
              <ShopButton
                shopUrl={this.props.pageContext.SHOPIFY_STORE}
                label={this._t('La boutique Wakatoon')}
              />
            </section>
          </React.Fragment>
        </div>
      </Layout>
    );
  }
}

export default Test;

export const pageQuery = graphql`
  query testPage($locale: String!) {
    testPageContent: allPrismicPageTesting(filter: { lang: { eq: $locale} }) {
      edges {
        node {
          lang
          data {
            seo__meta_title_ {
              text
            }
            seo__meta_description_ {
              text
            }
            page_title {
              text
            }
            page_subtitle {
              html
            }
            shop_link {
              html
            }
            stories {
              story {
                document {
                  data {
                    project_id
                    title {
                      text
                    }
                    test_image {
                      alt
                      url
                    }
                    test_alt_attribute
                    type
                    physical_book
                    printable
                    in_app_purchase
                    popup_background {
                      url
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    howItWorks: allPrismicSectionHowitworks(filter: { lang: { eq: $locale} }) {
      edges { ...howItWorks }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;